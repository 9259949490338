import { combineReducers } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import authReducer from '../auth';
import questionairreSlice from '../../../redux/questionairreModule/questionairreSlice';
import documentSlice from '../../../redux/documentModule/documentSlice';
import notesSlice from '../../../redux/notesModule/notesSlice';

const MainReducer = combineReducers(
  {
    auth: authReducer,
    questionairreSlice:questionairreSlice,
    documentSlice:documentSlice,
    notesSlice: notesSlice,
  }
);

const RootReducer = (state: any, action: any) => {
  if (action.type === "authentication/logout") {
    state = undefined
  }
  if (action.type === PURGE) {
    state = undefined
  }
  return MainReducer(state, action);
}
export default RootReducer;
