const EndPoint = {
    SET_ACCOUNT: '/auth/setup-account',
    RESET_PASSWORD: '/auth/reset-password',
    LOGIN_API: '/auth/login',
    FORGOT_PASSWORD: '/auth/forgot-password',
    QUESTIONAIRRE_LIST: `/cases/questionnaire-list`,
    CLIENT_CASES: `/cases/list`,
    CLIENT_APPLICANT_LIST: `/cases/applicant-list`,
    VIEW_QUESTIONNNAIRE: "cases/questionnaire-view",
    SUBMIT_QUESTIONNAIRE: "/cases/questionnaire-submit",
    NOTAPPLICABLE_QUESTIONNAIRE: "/cases/mark-questionnaire-section-notapplicable",
    DOCUMENT_LIST: `/cases/get-document-list`,
    DELETE_DOCUMENT_LIST: `/cases/delete-case-document`,
    UPLOAD_DOCUMENT_LIST: `/cases/upload-request-file-in-documents`,
    VIEW_CASE_DOCUMENT: "/cases/document",
    DOCUMENT_VIEW_DATA: '/cases/document-view',
    UPLOAD_DOCUMENT: "cases/upload-request-file-in-documents",
    EDIT_UPLOAD_DOCUMENT: "/cases/upload-request-file-in-documents-edit",
    KB_DOC_TYPE_LIST_API: "/common-apis/list",
    NOTES_LIST: `/notes/list`,
    NOTES_ADD: `/notes/add`,
    NOTES_EDIT: `/notes/update`,
    NOTES_VIEW: `/notes/detail`,
    NOTES_DELETE: `/notes/delete`,
    CHANGE_PASSWORD: "/auth/change-password",
    LOGOUT_API: "/auth/logout",
}

export default EndPoint;