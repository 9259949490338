import { FC, useState } from "react";
import { Form, Stack } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { toast } from 'react-toastify';
import { ALLOWEDFILETYPES, MAX_ALLOWED_FILES_SIZE_ALLOWED, POP_UP_CLOSE_2000 } from "../../constants/global";
interface IFileUploaderDrag {
    controlId?: string;
    label?: string;
    fileTypes: any;
    handlefileChange?: Function;
    multiple?:boolean;
}


//File Upload and Drag Common Component
const FileUploaderDragCustom: FC<IFileUploaderDrag> = ({
    controlId,
    label,
    fileTypes,
    handlefileChange,
    multiple
}) => {
    const [file, setFile] = useState(null);
    const handleTypeError = (error:any) => {
        toast.error(ALLOWEDFILETYPES +" "+ JSON.stringify(fileTypes), { autoClose: POP_UP_CLOSE_2000 });
    }
    const handleSizeError = (error:any) => {
        toast.error(MAX_ALLOWED_FILES_SIZE_ALLOWED, { autoClose: POP_UP_CLOSE_2000 });
    }
    
    const handleChange = async (file: any) => {
        console.log("##### handleChange ####", handlefileChange, file)
        setFile(null);
        if(handlefileChange){
            handlefileChange(file);
        }
    };
    const handleOnDrop = (file:any) =>{
        console.log("##### handleOnDrop ####", file)
    }
    const handleOnDragging = (file:any) =>{
        console.log("##### handleOnDragging ####", file)
    }
    return (
        <>
            <Form.Group controlId={controlId} className="theme-file-drag-uploader">
                {label ?
                    <span className="mb-1 d-block small form-label">{label}</span>
                    : ''
                }
                <div className="position-relative">
                    <FileUploader
                        fileOrFiles={file}
                        classes="w-100 theme-border-dashed"
                        handleChange={handleChange}
                        name={controlId}
                        types={fileTypes}
                        multiple={multiple ?? true}
                        onTypeError={handleTypeError}
                        maxSize={20}
                        onSizeError={handleSizeError}
                        onDrop={handleOnDrop}
                        onDraggingStateChange={handleOnDragging}
                    >
                        <Stack
                            direction="horizontal"
                            gap={1}
                            className="bg-body-secondary p-3 justify-content-center text-center min-h-100 rounded flex-wrap"
                            role="button"
                        >
                            <span className="fs-15 text-body-tertiary">Drag and drop document here -&nbsp;or&nbsp;- </span>
                            <span className="btn btn-outline-primary bg-white">Browse</span>
                        </Stack>
                    </FileUploader>
                </div>
            </Form.Group>

        </>
    )
};

export default FileUploaderDragCustom;
