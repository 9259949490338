import { Card } from 'react-bootstrap';
import RenderAction from '../../../components/common/dataGrid/ActionCell';
import DataGridCommon from '../../../components/common/dataGrid/DataGridCommon';
import { HIDE_TABLE_PAGINATOR } from '../../../constants/global';
import ViewNotesModal from '../modals/ViewNotesModal';
import { useState } from 'react';

const RecentNotes = () => {
    const [viewNotesModalShow, setViewNotesModalShow] = useState(false);
    const [notesInfo, setNotesInfo] = useState(null);

    // Data Table Start
    const actionCellTemplate = (rowData: any) => {
        return (
            <RenderAction
                controlId="event-alert"
                rowData={rowData}
                isViewPopupEnable={true}
                handleViewPopupClick={handleViewPopupClick}
            />
        );
    };

    const handleViewPopupClick = (rowData: any) => {
        setNotesInfo(rowData)
        setViewNotesModalShow(true)
    }

    // Table Columns
    const columns = [
        {
            field: "notes",
            header: "Notes",
        },
        {
            field: "updated_date",
            header: "Updated Date",
        },
        {
            field: "action",
            header: "Action",
            headerClassName: "theme-action-cell theme-table-cell-center",
            bodyClassName: "theme-action-btns-center",
            width: "100px",
            body: actionCellTemplate,
        },
    ];

    // Table Body Data
    const data = [
        {
            id: "1",
            notes: "Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing",
            updated_date: "7/25/2023",
            locked: false,
            action: "Action",
        },
        {
            id: "2",
            notes: "Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing",
            updated_date: "7/25/2023",
            locked: true,
            action: "Action",
        },
        {
            id: "3",
            notes: "Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing",
            updated_date: "7/25/2023",
            locked: true,
            action: "Action",
        },
        {
            id: "4",
            notes: "Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing",
            updated_date: "7/25/2023",
            locked: true,
            action: "Action",
        },
    ];

    return (
        <>
            <Card className="shadow-lg bg-white border-0 h-100">
                <Card.Header className="bg-transparent border-0 pb-0 pt-3">
                    <Card.Title className="mb-0">
                        Recent Notes
                    </Card.Title>
                </Card.Header>
                <Card.Body className="p-3">
                    <DataGridCommon
                        columns={columns}
                        data={data}
                        HideTablePaginator={HIDE_TABLE_PAGINATOR}
                    />
                </Card.Body>
            </Card>

            {/* View Notes Modal */}
            <ViewNotesModal
                show={viewNotesModalShow}
                handleClose={() => setViewNotesModalShow(false)}
                notesData={notesInfo}
            />
        </>
    )
}

export default RecentNotes