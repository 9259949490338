import { Card } from 'react-bootstrap';

const CaseStatus = () => {
    return (
        <Card className="shadow-lg bg-white border-0 h-100">
            <Card.Header className="d-flex align-items-center flex-wrap bg-white px-3 pt-3 pb-1 border-0 gap-2">
                <Card.Title className="fw-bold mb-0 me-auto text-primary fs-20">Case Status</Card.Title>
            </Card.Header>
            <Card.Body className="p-3 pt-0">
                Case No. NIW-1024  is in Requirement gathering stage.
            </Card.Body>
        </Card>
    )
}

export default CaseStatus