import { Menu } from 'primereact/menu';
import { FC, useRef } from "react";
import { Button, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { FaCheck, FaFileExport, FaFileUpload, FaLock, FaLockOpen, FaSignature } from "react-icons/fa";
import { IoIosCheckmarkCircle, IoIosCopy } from "react-icons/io";
import { MdAddCircleOutline, MdDelete, MdEdit, MdRefresh, MdRemoveRedEye } from "react-icons/md";
import { Link } from "react-router-dom";
import { EDIT, VIEW } from "../../../constants/global";
import appConfig from "../../../helpers/config";
import { SvgIcons } from "../SvgIcons";

export interface IRenderAction {
  controlId: string;
  redirectID?: string;
  isViewEnable?: boolean;
  disabledView?: boolean;
  isEditEnable?: boolean;
  disabledEdit?: boolean;
  isDeleteEnable?: boolean;
  handleDeleteClick?: any;
  disabledDelete?: boolean;
  rowData?: any;
  isMappingEnable?: boolean;
  disabledMapping?: boolean;
  goToMapping?: any;
  isAddMappingEnable?: boolean;
  goToAddMapping?: any;
  disabledAddMapping?: boolean;
  isViewPopupEnable?: boolean;
  handleViewPopupClick?: any;
  disabledViewPopup?: boolean;
  isViewStatusEnable?: boolean;
  disabledViewStatus?: boolean;
  handleViewStatus?: any;
  handleViewStatusClick?: any;
  isActionMoreEnable?: boolean;
  handleRenameClick?: any;
  disabledActionMore?: boolean;
  isEditPopupEnable?: boolean;
  handleEditPopupClick?: any;
  disabledEditPopup?: boolean;
  isLockEnable?: boolean;
  handleLockClick?: any;
  disabledLock?: boolean;
  lockStatus?: boolean;
  isResetPopupEnable?: boolean;
  handleResetPopupClick?: any;
  disabledResetPopup?: boolean;
  isCopyPopupEnable?: boolean;
  handleCopyPopupClick?: any;
  disabledCopyPopup?: boolean;
  isSignPopupEnable?: boolean;
  handleSignPopupClick?: any;
  disabledSignPopup?: boolean;
  isMarkAsCompletedEnable?: boolean;
  handleMarkAsCompletedClick?: any;
  disabledMarkAsCompleted?: boolean;
  isDownloadAsCsvEnable?: boolean;
  handleDownloadAsCsvClick?: any;
  disabledDownloadAsCsv?: boolean;
  isUploadEnable?: boolean;
  handleUploadClick?: any;
  disabledUpload?: boolean;
}

const renderTooltip = (props: any) => <Tooltip id={props}>{props}</Tooltip>;

//Render Action Functions For Data Grid
const RenderAction: FC<IRenderAction> = ({
  controlId,
  redirectID,
  isViewEnable,
  disabledView,
  isEditEnable,
  disabledEdit,
  isDeleteEnable,
  handleDeleteClick,
  disabledDelete,
  rowData,
  isMappingEnable,
  disabledMapping,
  goToMapping,
  isAddMappingEnable,
  goToAddMapping,
  disabledAddMapping,
  isViewPopupEnable,
  handleViewPopupClick,
  disabledViewPopup,
  isViewStatusEnable,
  handleViewStatus,
  disabledViewStatus,
  handleViewStatusClick,
  isActionMoreEnable,
  disabledActionMore,
  handleRenameClick,
  isEditPopupEnable,
  handleEditPopupClick,
  disabledEditPopup,
  isLockEnable,
  handleLockClick,
  disabledLock,
  lockStatus,
  isResetPopupEnable,
  handleResetPopupClick,
  disabledResetPopup,
  isCopyPopupEnable,
  handleCopyPopupClick,
  disabledCopyPopup,
  isSignPopupEnable,
  handleSignPopupClick,
  disabledSignPopup,
  isMarkAsCompletedEnable,
  handleMarkAsCompletedClick,
  disabledMarkAsCompleted,
  isDownloadAsCsvEnable,
  handleDownloadAsCsvClick,
  disabledDownloadAsCsv,
  isUploadEnable,
  handleUploadClick,
  disabledUpload,
}) => {
  const popupMenu = useRef<Menu | null>(null);

  //Actions Menu Items
  let actionsMenuitems = [
    {
      label: 'Rename Step',
      command: () => {
        handleRenameClick(rowData);
      },
    }
  ];
  if (!disabledDelete) {
    actionsMenuitems.push({
      label: 'Delete Step',
      command: () => {
        handleDeleteClick(rowData);
      }
    });
  }

  return (
    <Stack direction="horizontal" className="theme-action-btns">

      {isViewStatusEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip(handleViewStatus ? "Completed" : "Pending")} trigger={["hover", "hover"]}>
          <Button
            variant="link"
            onClick={() => {
              handleViewStatusClick(rowData);
            }}
            className={`p-0 lh-1 mx-1 theme-view-status-btn ${handleViewStatus ? 'text-success' : 'opacity-25 text-body'}`}
            disabled={disabledViewStatus}
          >
            <IoIosCheckmarkCircle size={29} />
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isActionMoreEnable ? (
        <>
          <OverlayTrigger placement="top" overlay={renderTooltip("Actions")} trigger={["hover", "hover"]}>
            <Button
              variant="link"
              className="lh-1 p-1 w-28 text-body theme-action-more-btn"
              onClick={(e) => {
                if (popupMenu.current) {
                  popupMenu.current.toggle(e);
                }
              }}
              disabled={disabledActionMore}
            >
              {SvgIcons.dotMenuicon}
            </Button>
          </OverlayTrigger>
          <Menu
            model={actionsMenuitems}
            popup ref={popupMenu}
            className="theme-actions-menu"
          />
        </>
      ) : (
        ""
      )}

      {isLockEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip(lockStatus ? "Locked" : "Lock")} trigger={["hover", "hover"]}>
          <Button
            className="p-1 lh-1 fs-5 theme-lock-btn"
            variant="link"
            // onClick={() => {
            //   handleLockClick(rowData);
            // }}
            disabled={disabledLock}
          >
            {lockStatus ? <FaLock size={16} /> : <FaLockOpen size={16} />}
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isUploadEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip("Upload")} trigger={["hover", "hover"]}>
          <Button
            className="p-1 lh-1 fs-14 theme-upload-btn"
            variant="link"
            onClick={() => {
              handleUploadClick(rowData);
            }}
            disabled={disabledUpload}
          >
            <FaFileUpload />
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isViewEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip("View")} trigger={["hover", "hover"]}>
          <Link
            className={`p-1 lh-1 fs-5 theme-view-btn text-secondary ${disabledView ? 'pe-none opacity-50' : ''}`}
            to={`${appConfig.basePath}/${controlId}/${VIEW}/${rowData.id}`}

          >
            <MdRemoveRedEye />
          </Link>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isViewPopupEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip("View")} trigger={["hover", "hover"]}>
          <Button
            className="p-1 lh-1 fs-5 theme-view-popup-btn"
            variant="link"
            onClick={() => {
              handleViewPopupClick(rowData);
            }}
            disabled={disabledViewPopup}
          >
            <MdRemoveRedEye />
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isSignPopupEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip("Signing Authority")} trigger={["hover", "hover"]}>
          <Button
            className="p-1 lh-1 fs-5 theme-sign-popup-btn"
            variant="link"
            onClick={() => {
              handleSignPopupClick(rowData);
            }}
            disabled={disabledSignPopup}
          >
            <FaSignature size={16} />
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isMarkAsCompletedEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip("Mark as Completed")} trigger={["hover", "hover"]}>
          <Button
            className="p-1 lh-1 fs-5 min-w-30 theme-mark-as-completed-btn"
            variant="link"
            onClick={() => {
              handleMarkAsCompletedClick(rowData);
            }}
            disabled={disabledMarkAsCompleted}
          >
            <FaCheck size={14} />
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isDownloadAsCsvEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip("Download as csv")} trigger={["hover", "hover"]}>
          <Button
            className="p-1 lh-1 fs-5 min-w-30 theme-download-as-csv-btn"
            variant="link"
            onClick={() => {
              handleDownloadAsCsvClick(rowData);
            }}
            disabled={disabledDownloadAsCsv}
          >
            <FaFileExport size={16} />
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isEditEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip("Edit")} trigger={["hover", "hover"]}>
          <Link
            className={`p-1 lh-1 fs-5 theme-edit-btn text-secondary ${disabledEdit ? 'pe-none opacity-50' : ''}`}
            to={`${appConfig.basePath}/${controlId}/${EDIT}/${rowData.id}`}

          >
            <MdEdit />
          </Link>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isEditPopupEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip("Edit")} trigger={["hover", "hover"]}>
          <Button
            className="p-1 lh-1 fs-5 theme-edit-popup-btn"
            variant="link"
            onClick={() => {
              handleEditPopupClick(rowData);
            }}
            disabled={disabledEditPopup}
          >
            <MdEdit />
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isResetPopupEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip("Reset")} trigger={["hover", "hover"]}>
          <Button
            className="p-1 lh-1 fs-5 theme-reset-popup-btn"
            variant="link"
            onClick={() => {
              handleResetPopupClick(rowData);
            }}
            disabled={disabledResetPopup}
          >
            <MdRefresh size={18} className="theme-flip-x" />
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isCopyPopupEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip("Copy")} trigger={["hover", "hover"]}>
          <Button
            className="p-1 lh-1 fs-5 theme-copy-popup-btn"
            variant="link"
            onClick={() => {
              handleCopyPopupClick(rowData);
            }}
            disabled={disabledCopyPopup}
          >
            <IoIosCopy size={14} />
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isDeleteEnable ? (
        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} trigger={["hover", "hover"]}>
          <Button
            className="p-1 lh-1 fs-5 theme-delete-btn"
            variant="link"
            onClick={(e) => {
              handleDeleteClick(rowData);
            }}
            disabled={disabledDelete}
          >
            <MdDelete />
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isMappingEnable ? (
        <OverlayTrigger placement="top" overlay={renderTooltip("Form Mapping")} trigger={["hover", "hover"]}>
          <Button
            className="p-1 lh-1 fs-5 theme-mapping-btn"
            variant="link"
            onClick={goToMapping}
            disabled={disabledMapping}
          >
            {SvgIcons.fileShareIcon}
          </Button>
        </OverlayTrigger>
      ) : (
        ""
      )}

      {isAddMappingEnable ? (
        <Button
          className="theme-add-mapping-btn py-1 px-2"
          variant="primary"
          onClick={goToAddMapping}
          disabled={disabledAddMapping}
        >
          <MdAddCircleOutline size={17} className="me-2" />
          Add New Mapping
        </Button>

      ) : (
        ""
      )}

    </Stack>
  );
};

export default RenderAction;
