import * as yup from "yup";
import { VALIDATION_MESSAGES } from "./validationErrors";

/**
 * Documents => Upload Document Modal Schema for Validation
 * @date 1/3/2024 - 11:49:26 AM
 *
 * @type {*}
 */

export const DOCUMENT_NAME_REGEX = /^[A-Za-z0-9\-_& ]*$/;

export const UploadDocumentModalSchema: any = yup.object().shape({
    document_name: yup
        .string()
        .required(VALIDATION_MESSAGES.DOCUMENT_NAME_REQUIRED).matches(DOCUMENT_NAME_REGEX, VALIDATION_MESSAGES.DOCUMENT_NAME_VALIDATION)
        .trim(VALIDATION_MESSAGES.TRIM_VALIDATION).strict(true),

    document_type: yup
        .object()
        .required(VALIDATION_MESSAGES.DOCUMENT_TYPE_REQUIRED),

    upload_to_folder: yup
        .string().required().label("Folder Selection"),
    move_to_folder: yup
        .string(),
    applicants: yup
        .array().min(1).label("Applicants"),
    description: yup
        .string()
        .max(1500).label('Description'),
    document: yup
        .string(),
});
