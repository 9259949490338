import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Documents = () => {
    const documentsData = [
        {
            id: 1,
            title: "10 Documents",
            status: "Completed",
        },
        {
            id: 2,
            title: "25 Documents",
            status: "Pending"
        },
        {
            id: 3,
            title: "5 Documents",
            status: "Review"
        },
    ]

    return (
        <Card className="shadow-lg bg-white border-0 h-100">
            <Card.Header className="d-flex align-items-center flex-wrap bg-white p-3 border-0 gap-2">
                <Card.Title className="fw-bold mb-0 me-auto text-primary fs-20">Documents</Card.Title>
                <Link to="/documents" className="text-decoration-underline">View All</Link>
            </Card.Header>
            <Card.Body className="p-3 pt-0">
                <ul className="list-unstyled">
                    {documentsData.map((dataItems: any, index: number) => {
                        const { id, title, status } = dataItems;
                        const isLastItem = index === documentsData.length - 1;
                        const classNotForLastChild = isLastItem ? '' : 'border-bottom pb-2 mb-2';

                        // Set color based on status
                        let statusColor = '';
                        switch (status) {
                            case 'Completed':
                                statusColor = 'dashboard-text-green';
                                break;
                            case 'In Progress':
                                statusColor = 'dashboard-text-gray';
                                break;
                            case 'Pending':
                                statusColor = 'dashboard-text-orange';
                                break;
                            default:
                                statusColor = 'dashboard-text-red';
                        }

                        return (
                            <li key={id} className={`d-flex gap-2 ${classNotForLastChild}`}>
                                <div className="me-auto">{title}</div>
                                <div className={`text-end ${statusColor}`}>{status}</div>
                            </li>
                        )
                    })}
                </ul>
            </Card.Body>
        </Card>
    )
}

export default Documents