import { Card } from 'react-bootstrap';
import RenderAction from '../../../components/common/dataGrid/ActionCell';
import DataGridCommon from '../../../components/common/dataGrid/DataGridCommon';
import { HIDE_TABLE_PAGINATOR } from '../../../constants/global';

const EventAlert = () => {
    // Data Table Start  

    const actionCellTemplate = (rowData: any) => {
        return (
            <RenderAction
                controlId="event-alert"
                rowData={rowData}
                isViewPopupEnable={true}
                handleViewPopupClick={handleViewPopupClick}
            />
        );
    };

    const handleViewPopupClick = (rowData: any) => {
        console.log('rowData', rowData)
    }

    // Table Columns
    const columns = [
        {
            field: "title",
            header: "Title",
        },
        {
            field: "date",
            header: "Date",
        },
        {
            field: "time",
            header: "Time",
        },
        {
            field: "location",
            header: "Location",
        },
        {
            field: "event_note",
            header: "Event Note",
        },
        {
            field: "action",
            header: "Action",
            headerClassName: "theme-action-cell theme-table-cell-center",
            bodyClassName: "theme-action-btns-center",
            width: "100px",
            body: actionCellTemplate,
        },
    ];

    // Table Body Data
    const data = [
        {
            id: "1",
            title: "Interview appointment with USCIS",
            date: "7/25/2023",
            time: "11:30 AM",
            location: "USCIS Office",
            event_note: "Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing",
            action: "Action",
        },
        {
            id: "2",
            title: "Interview appointment with USCIS",
            date: "7/25/2023",
            time: "11:30 AM",
            location: "USCIS Office",
            event_note: "Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing",
            action: "Action",
        },
        {
            id: "3",
            title: "Interview appointment with USCIS",
            date: "7/25/2023",
            time: "11:30 AM",
            location: "USCIS Office",
            event_note: "Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing",
            action: "Action",
        },
        {
            id: "4",
            title: "Interview appointment with USCIS",
            date: "7/25/2023",
            time: "11:30 AM",
            location: "USCIS Office",
            event_note: "Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing",
            action: "Action",
        },
    ];

    return (
        <Card className="shadow-lg bg-white border-0 h-100">
            <Card.Header className="bg-transparent border-0 pb-0 pt-3">
                <Card.Title className="mb-0">
                    Event Alert
                </Card.Title>
            </Card.Header>
            <Card.Body className="p-3">
                <DataGridCommon
                    columns={columns}
                    data={data}
                    HideTablePaginator={HIDE_TABLE_PAGINATOR}
                />
            </Card.Body>
        </Card>
    )
}

export default EventAlert