import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../store/common-api/axios';
import EndPoint from '../../api/endpoint';
const initialState = {
    questionnaire: [],
    loading: false,
    caseID: "",
    lastSavedSectionID: null

};

export const getDocumentListAction = createAsyncThunk(
    "documentModule/getDocumentListAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.DOCUMENT_LIST}?case_id=${value.case_id}&client_id=${value.client_id}&derivates_id=${value.derivates_id}`;
        try {
            const response = await axiosRequest.get(apiUrl);

            return response;
        } catch (error: any) {
            return error;

        }
    }
);

export const deleteCaseDocumentAction = createAsyncThunk(
    "documentModule/deleteCaseDocumentAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.DELETE_DOCUMENT_LIST}/${value.case_id}/${value.doc_id}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const getKnowledgeBaseDocTypesListAction = createAsyncThunk(
    "knowledgebasedocchkitemsModule/getKnowledgeBaseDocTypesListAction",
    async () => {
        const apiUrl = EndPoint.KB_DOC_TYPE_LIST_API;
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {

            return error;
        }
    }
);

export const getDocDetialsByIDAction = createAsyncThunk(
    "caseManagement/getDocDetialsByIDAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.VIEW_CASE_DOCUMENT}/${value.doc_id}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const edituploadCaseDocumentsByCaseAction = createAsyncThunk(
    "caseManagement/edituploadCaseDocumentsByCaseAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.post(`${EndPoint.EDIT_UPLOAD_DOCUMENT}`, value);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const getAllApplicantList = createAsyncThunk(
    "teamsModule/getAllApplicantList",
    async (value: any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.CLIENT_APPLICANT_LIST}?case_id=${value.caseTypesID}`)
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const getDocContentByIDAction = createAsyncThunk(
    "caseManagement/getDocContentByIDAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.DOCUMENT_VIEW_DATA}/${value.file_id}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const uploadCaseDocumentsByCaseAction = createAsyncThunk(
    "caseManagement/uploadCaseDocumentsByCaseAction",
    async (value: any) => {
      try {
        const response = await axiosRequest.post(`${EndPoint.UPLOAD_DOCUMENT}`, value);
        return response;
      } catch (error: any) {
        return error;
      }
    }
  );

const documentSlice = createSlice({
    name: 'Questoinairre Management',
    initialState: initialState,
    reducers: {
        setCaseID(state, actions) {
            state.caseID = actions.payload.caseID;
        },
        startLoader(state, actions) {
            state.loading = true;
        },
        stopLoader(state, actions) {
            state.loading = false;
        },
        saveLastSection(state, action) {
            state.lastSavedSectionID = action.payload.lastSavedSectionID
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDocumentListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDocumentListAction.fulfilled, (state, action) => {
                state.loading = false;
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.questionnaire = data;
            })
            .addCase(getDocumentListAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteCaseDocumentAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteCaseDocumentAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteCaseDocumentAction.rejected, (state, action) => {
                state.loading = false;
            })

    }
});

export const documentActions = documentSlice.actions;

export default documentSlice.reducer;