import { Col, Row, Stack } from 'react-bootstrap';
import { FaMoneyBillWave } from 'react-icons/fa';
import { SvgIcons } from '../../../components/common/SvgIcons';

const BillingStatus = () => {
    const glanceData = [
        {
            id: 1,
            title: "Current",
            subTitle: "Billing Account Status",
            bgColorClass: "alert-success",
            icon: SvgIcons.billingIcon,
        },
        {
            id: 2,
            title: "33%",
            subTitle: "Progress of Payment",
            bgColorClass: "alert-default",
            icon: SvgIcons.dashboardPaymentIcon,
        },
        {
            id: 3,
            title: "35 Days",
            subTitle: "Installment Overdue",
            bgColorClass: "alert-danger",
            icon: <FaMoneyBillWave size={32} />,
        },
    ]
    return (
        <Row className="g-3 g-xl-4">
            {glanceData.map((glanceItem: any) => {
                const { id, title, subTitle, bgColorClass, icon } = glanceItem;
                return (
                    <Col
                        key={id}
                        sm={6}
                        lg={4}
                        className="mw-1 text-wrap"
                    >
                        <Stack
                            direction="horizontal"
                            gap={3}
                            className={`alert border-0 gap-3 h-100 hstack m-0 px-3 py-1 w-100 rounded-1 ${bgColorClass}`}
                        >
                            <Stack
                                direction="horizontal"
                                gap={2}
                                className="gap-md-3 gap-xl-4 me-auto text-body"
                            >
                                <h4 className="mb-0 fw-semibold">{title}</h4>
                                <small>{subTitle}</small>
                            </Stack>
                            <span>
                                {icon}
                            </span>
                        </Stack>
                    </Col>
                )
            })}
        </Row>
    )
}

export default BillingStatus