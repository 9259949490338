import moment from "moment";
import { FC } from "react";
import { Modal, Stack } from "react-bootstrap";
import { FaLock } from "react-icons/fa";

interface IViewNotesModal {
  show?: boolean;
  handleClose?: any;
  notesData?: any
}

/**
 * View Notes Modal Component
 *
 * @param {{ rowData: any; show: any; handleClose: any; }} {
  rowData,
  show,
  handleClose,
}
 * @returns {*}
 */

const ViewNotesModal: FC<IViewNotesModal> = ({
  show,
  handleClose,
  notesData
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered={true}
      scrollable={true}
      size="lg"
      className="theme-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title as="div" className="flex-fill pe-3">
          <Stack
            direction="horizontal"
            gap={2}
          >
            <h6 className="fw-semibold mb-0 me-auto">View Notes</h6>

            <div className="small pe-3 lh-sm">
              <span className="fw-semibold">Created by:</span> <span className="text-capitalize"> {("JoHn Due").toLowerCase()}</span> <br /> {moment(notesData?.updated_date).format('MM/DD/YYYY hh:mm:ss')}
            </div>
            {notesData?.locked ?
              <div
                className="bg-black bg-opacity-10 d-inine-block fs-12 lh-lg px-2 py-1 rounded rounded-1 text-body-secondary"
              >
                <span className="px-1">
                  <FaLock size={14} className="me-2" />
                  Locked by Creator
                </span>
              </div> : ""}
          </Stack>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="pb-3">
        <div className="theme-modal-form-content theme-lh-lg small">

          <p dangerouslySetInnerHTML={{__html: notesData?.notes}} />

        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewNotesModal;