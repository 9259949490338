export const VALIDATION_MESSAGES = {
    EMAIL_REQUIRED: "Email address is required.",
    VALID_EMAIL: "Please enter a valid email.",
    PASSWORD_REQUIRED: "Password is required.",
    PASSWORD_INVALID: "Password must be eight characters and contain one capital letter, one small letter, one digit, and one special character.",
    PASSWORD_MUST_MATCH: "Confirm password must match with new password.",
    DOCUMENT_NAME_REQUIRED: "Document name is required.",
    DESCRIPTION_MAX: "Description must be less than or equal to 1500 characters",
    DOCUMENT_TYPE_REQUIRED: "Document type is required.",
    DOCUMENT_NAME_VALIDATION: "Document Name should only contain alphabets, digits and - & _ characters.",
    TRIM_VALIDATION:"Please remove spaces from start and end of field's data",
}

export const API_CUSTOM_MESSAGE = {
    SOMETHING_WRONG : "Something went wrong!"
}