import { useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import ThemeCustomDropdown from "../../components/common/CustomDropdown";
import Loader from "../../components/common/Loader";
import "./dashboard.scss";
import BillingStatus from "./sections/BillingStatus";
import CaseStatus from "./sections/CaseStatus";
import ChecklistStatus from "./sections/ChecklistStatus";
import Documents from "./sections/Documents";
import Questionnaires from "./sections/Questionnaires";
import EventAlert from "./sections/EventAlert";
import RecentNotes from "./sections/RecentNotes";

const Dashboard = () => {
    const [caseOptions, setCaseOptions] = useState<any>('NIW-1024');
    const isLoading = useSelector((state: any) => state.auth.loading);

    // Team Options
    const caseOptionsData = [
        { label: "NIW-1024", value: "NIW-1024" },
        { label: "NIW-1028", value: "NIW-1028" },
    ];

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
                {/* Header */}
                <Stack
                    direction="horizontal"
                    gap={2}
                    className="flex-wrap mb-2"
                >
                    <h1 className="fs-4 fw-semibold mb-0 me-auto">Dashboard</h1>
                    <Stack
                        direction="horizontal"
                        gap={1}
                    >
                        <span className="small">Case#:</span>
                        <div className="w-250">
                            <ThemeCustomDropdown
                                controlId="team_dashboard"
                                placeHolder="Team"
                                data={caseOptionsData}
                                value={caseOptions}
                                handleFilter={(e: any) => {
                                    setCaseOptions(e.value);
                                }}
                            />
                        </div>
                    </Stack>
                </Stack>

                <Row className="gx-3 py-2">
                    {/* Billing Status Section */}
                    <Col xs={12} className="mb-2 pb-1">
                        <BillingStatus />
                    </Col>

                    <Col lg={7} className="mb-3 pb-1">
                        <Row className="g-3">
                            {/* Questionnaires Section */}
                            <Col sm={6}>
                                <Questionnaires />
                            </Col>
                            {/* Documents Section */}
                            <Col sm={6}>
                                <Documents />
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={5}>
                        <Row className="gy-2 gx-3">
                            {/* Case Status Section */}
                            <Col sm={6} lg={12}>
                                <CaseStatus />
                            </Col>
                            {/* Checklist Status Section */}
                            <Col sm={6} lg={12}>
                                <ChecklistStatus />
                            </Col>
                        </Row>
                    </Col>

                    {/* Event Alert Section */}
                    <Col xs={12} className="mb-3">
                        <EventAlert />
                    </Col>

                    {/* Recent Notes Section */}
                    <Col xs={12} className="mb-3">
                        <RecentNotes />
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default Dashboard;